<template>
  <div class="all">
    <!--    顶部Banner-->
    <div class="title_Img">
      <div class="title">
        <div class="imgLogo"><img class="home" src="../assets/img/logo2.png">
        <p>环宇建筑科技股份有限公司</p>
        </div>
        <div class="tab_but">
          <router-link :to="{name:'Home'}" class="but_tab_Home"> 网站首页</router-link>
          <router-link :to="{name:'Recruiting'}" class="but_tab"> 招标公告</router-link>
          <router-link :to="{name:'Winning'}" class="but_tab"> 中标公示</router-link>
          <router-link :to="{name:'Service'}" class="but_tab"> 企业服务</router-link>
          <router-link :to="{name:'Help'}" class="but_tab"> 帮助中心</router-link>
          <div class="but_tab" @click="exitBut" v-if="id !== null">退出登录</div>
        </div>
      </div>
      <el-carousel :interval=2800 height="660px">
        <el-carousel-item v-for="(item,index) in imgSrc" :key="index">
          <img :src="imgSrc[index].img" class="img_bak">
        </el-carousel-item>
      </el-carousel>
    </div>

    <!--    企业注册、企业登录-->
    <div class="login_zc" v-if="id === null">
      <div class="registered">
        <img src="../assets/img/logo4.png">
        <div class="r_zc" @click="registered">企业注册</div>
      </div>

      <div class="registered">
        <img src="../assets/img/logo5.png">
        <div class="r_zc" @click="login">企业登录</div>
      </div>
    </div>
    <!--    招标公告-->
    <div class="sign">
      <span>招标公告</span>
      <div class="top_b"></div>
      <div class="banner_sign">
        <div v-for="(item,index) in tender" :key="index" class="msg_sign">
          <div class="mouse-hover" @click="Sign(item)">{{ item.subject }}</div><br>
          <div class="text_tile">
            <span>
<!--              <p>{{ item.name.slice(4, 11) }}</p>-->
              <p>招标状态：{{item.status === 'pending'? '进行中':'已截止'}}</p>
            <p>发布日期：{{ item.createDate }}</p>
            <p> 报名截止：{{ item.signUpEndDate }}</p>
            </span>
            <div class="but" @click="Sign(item)">报名</div>
          </div>
        </div>
      </div>
      <div style="width: 100%;margin: -100px auto;display: flex;justify-content: center">
        <button class="look_gd mouse-hover" @click="tenderBut()">查看更多<i class="el-icon-arrow-right"></i></button>
      </div>
    </div>

    <!--    中标公告-->
    <div class="sign">
      <span>中标公告</span>
      <div class="top_b"></div>
      <div class="banner_sign1">
        <div v-for="(item,index) in WinningName" :key="index" class="msg_sign">
          <span>{{ item.projectName }}</span>
          <div class="text_tile1">
            <p>中标单位： {{item.supplierNames}}</p>
            <p>招标内容：  {{item.name}}</p>
          </div>
        </div>
      </div>
      <div style="width: 100%;margin: -100px auto;display: flex;justify-content: center">
        <button class="look_gd mouse-hover" @click="winningBut()">查看更多<i class="el-icon-arrow-right"></i></button>
      </div>
    </div>
    <!--    关于我们-->
    <div class="my">
      <span>关于我们</span>
      <div class="top_b1"></div>
      <div class="enterprise">
        <div class="dv1">
          <img src="../assets/img/logo7.png">
        </div>
        <div class="text_wb">
          <p v-html="enterprise_text">
          </p>
        </div>
      </div>
    </div>

    <!--    底部-->
    <div class="bottom_d">
      <div class="logo_d">
        <img src="../assets/img/back.png">
        <p>
          地址：浙江省绍兴市凤林西路300号环宇大厦<br>
          电话：0575-85132445 85336908<br>
          传真：0575-85117404 85336900<br>
          <a href="https://beian.miit.gov.cn/#/Integrated/index" style="color: #FFFFFF">备案：浙ICP备11004969号-1</a>
        </p>
      </div>
      <div class="wx_logo">
        <img src="../assets/img/logo3.png">
        <p>
          微信公众号：hyjzkj
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import { exitLogin, getBlacklistByApi, MyEnterprise, RecruitingAt, Winning } from '@/api/api'
import axios from 'axios'

export default {
  name: 'Home',
  data () {
    return {
      imgSrc: [
        {
          img: require('../assets/img/banner.jpg')
        },
        {
          img: require('../assets/img/login_bk.png')
        },
        {
          img: require('../assets/img/regi.png')
        }
      ],
      tender: [],
      WinningList: [],
      WinningName: [],
      enterprise_text: '',
      id: '',
      statusS: []
    }
  },
  created () {
    this.MyEnterpriseS()
    this.rec()
    this.gong()
    this.userBak()
    this.id = localStorage.getItem('EUJSESSIONID')
    this.userSupplier()
  },
  mounted () {
    this.updateIcon()
  },
  methods: {
    updateIcon () {
      const link = document.querySelector("link[rel*='icon']") || document.createElement('link')
      link.rel = 'shortcut icon'
      link.type = 'image/x-icon'
      link.href = require('../assets/img/hyjt.ico')
      document.getElementsByTagName('head')[0].appendChild(link)
    },
    userSupplier () {
      getBlacklistByApi({
        method: 'isUserLong'
      }, res => {
        // console.log(res.data.data)
        localStorage.setItem('userSupplier', res.data.data)
      })
    },
    userBak () {
      // 查询是否是黑名单用户  黑名单用户才展示按钮
      getBlacklistByApi({
        method: 'getBlacklistBy'
      }, res => {
        if (res.data.data === 'no') {
          localStorage.setItem('blackUser', '正常用户')
        } else {
          localStorage.setItem('blackUser', '黑名单')
        }
      })
    },
    Sign (item) {
      this.$router.push({ name: 'Recruiting', params: { Rid: item.id } })
    },
    tenderBut () {
      this.$router.replace('/Recruiting')
    },
    winningBut () {
      this.$router.replace('/Winning')
    },
    registered () {
      this.$router.replace('/login/registration')
    },
    login () {
      this.$router.replace('/login/login')
    },
    help () {
    },
    MyEnterpriseS () {
      axios.defaults.headers.token = localStorage.getItem('token')
      MyEnterprise({
        method: 'about',
        frName: '关于我们'
      }, res => {
        this.enterprise_text = res.data.data[0].content
      })
    },
    async rec () {
      axios.defaults.headers.token = localStorage.getItem('token')
      await RecruitingAt({
        method: 'tenderInfos',
        size: '5',
        page: '1',
        _type_: 'date',
        subject: '',
        condition: 'createDate',
        // condition: 'entryTime',
        value: ''
        // status: 'pending'
      }, res => {
        this.tender = res.data.data.data.slice(0, 4)
        for (let i = 0; i < this.tender.length; i++) {
          this.statusS = this.tender[i].status
          // console.log(this.tender[i].status)
          // console.log(this.statusS)
        }
      })
    },
    async gong () {
      axios.defaults.headers.token = localStorage.getItem('token')
      await Winning({
        // method: 'suppliers',
        // size: '5',
        // page: '1',
        // supplierName_like: ''
        method: 'tenderInfos',
        size: '5',
        page: '1',
        _type_: 'date',
        subject: '',
        condition: 'createDate',
        // condition: 'entryTime',
        value: '',
        status: 'done'
      }, res => {
        this.WinningName = res.data.data.data.slice(0, 4)
        for (let i = 0; i < this.WinningName.length; i++) {
          this.WinningList.entryTime = this.dateFormat(this.WinningList.entryTime)
          this.WinningList.biddingTime = this.dateFormat(this.WinningList.biddingTime)
          this.WinningList.duration = this.dateFormat(this.WinningList.duration)
        }
      })
    },
    dateFormat (time) {
      // 将13位时间戳转换成时间格式  输出为2018-10-09
      const date = new Date(time)
      const year = date.getFullYear()
      let month = date.getMonth() + 1
      let day = date.getDate()
      month = month < 10 ? '0' + month : month
      day = day < 10 ? '0' + day : day
      var myDate = ''
      myDate = year + '-' + month + '-' + day
      return myDate
    },
    // 退出登录
    exitBut () {
      exitLogin({
        method: 'logout'
      }, res => {
        this.$message.info('已注销用户！')
        this.$router.replace('/login/login')
        localStorage.clear()
        this.$cookies.remove('token', 'EUJSESSIONID=egCR0M2aKCuk46H9fucdWyPBsqeZTFGnU%2Fylt4meBeE%3D')
        localStorage.removeItem('EUJSESSIONID')
      })
    }
  }
}
</script>

<style scoped>
* {
  margin: 0;
  padding: 0;
  text-decoration: none;
}

.home {
  cursor: pointer;
}

.el-carousel {
  overflow-y: hidden;
  overflow-x: hidden;
}

/*最外层盒子*/
.all {
  width: 100%;
  margin: 0 auto;
  background-color: #f7f8f9;
}

.img_bak {
  margin: 0 auto;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
}

/*顶部title*/
.title_Img {
  width: 100%;
  height: 660px;
  margin: 0 auto;
  /*background-color: #EA5D5C;*/
  /*opacity: 0.68;*/
}

.title {
  position: absolute;
  z-index: 100;
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 90px;
  background-color: #000000;
  opacity: 0.68;
}

.imgLogo > img {
  width: 359px;
  height: 54px;
  padding-top: 10px;
}
.imgLogo >p{
  color: #FFFFFF;
  line-height: 10px;
  padding-left: 67px;
  letter-spacing: 1px;
  font-size: 23.2px;
  cursor: pointer;
  font-weight: bold;
}
.tab_but {
  display: flex;
  width: 600px;
  height: 90px;
  color: #ffffff;
  padding-top: 20px;
  text-align: center;
}

.but_tab {
  font-size: 16px;
  width: 220px;
  height: 40px;
  line-height: 40px;
  color: white;
  margin: 0 10px;
  text-decoration: none;
}

.but_tab_Home {
  font-size: 16px;
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  line-height: 40px;
  cursor: pointer;
  text-decoration: none;
}

.but_tab:hover {
  width: 220px;
  height: 40px;
  color: #10539B;
  background-color: white;
  cursor: pointer;
}

/*login登录注册*/
.login_zc {
  width: 600px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-evenly;
  align-items: center;
  /*background-color: #a1b28d;*/
  margin: 0 auto;
  height: 300px;
}

.registered {
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  margin: 0 auto;
  /*background-color: #EEEEEE;*/
}

.registered > img {
  width: 70px;
  height: 70px;
}

.r_zc {
  width: 120px;
  height: 46px;
  background-color: #10539B;
  text-align: center;
  line-height: 45px;
  color: white;
  border-radius: 10px;
  margin: 20px auto;
}

.r_zc:hover {
  cursor: pointer;
}

/*招标公告*/
.sign {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  width: 100%;
  height: 550px;
  margin: 80px auto;
  /*border: 1px solid red;*/
}

.sign > span {
  letter-spacing: 8px;
  font-size: 36px;
  font-weight: bold;
}

.top_b {
  float: left;
  width: 160px;
  height: 5px;
  background-color: #10539B;
  opacity: 0.8;
  margin-top: 40px;
  margin-left: -174px;
}

.banner_sign {
  display: flex;
  justify-content: center;
  margin: 0 auto;
  width: 100%;
  height: 450px;
  background-image: url("../assets/img/logo8.png");
  background-size: 100% 100%;
}

.msg_sign {
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  width: 285px;
  height: 160px;
  background-color: #ffffff;
  border-radius: 10px;
  margin-left: 20px;
  margin-top: 80px;
  text-align: left;
  padding: 0px 20px;
  line-height: 16px;
}

.msg_sign:first-of-type {
  margin-left: 0;
}

.text_tile{
  width: 100%;
  height: 39%;
  display: flex;
  justify-content: space-between;
  text-align: left;
}
.text_tile1{
  width: 100%;
  height: 39%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  text-align: left;
}
.text_tile1>p{
  display: flex;
  flex-direction: column;
  line-height: 25px;
  color: #666666;
  font-size: 14px;
}
.msg_sign > p{
  color: #333333;
  font-size: 16px;
}

.text_tile > span {
  display: flex;
  flex-direction: column;
  color: #666666;
  font-size: 14px;
}

.but {
  width: 40px;
  height: 24px;
  text-align: center;
  color: white;
  line-height: 24px;
  margin-top: 24px;
  background-color: #10539B;
  border-radius: 4px;
  cursor: pointer;
  box-sizing: border-box;
}

.look_gd {
  width: 140px;
  height: 42px;
  background-color: #10539B;
  text-align: center;
  line-height: 20px;
  border: none;
  color: white;
  font-size: 16px;
  border-radius: 4px;
}

.banner_sign1 {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 450px;
  background-image: url("../assets/img/logo6.png");
  background-size: 100% 100%;
}

/*关于我们*/
.my {
  width: 100%;
  /*height: 720px;*/
  /*background-color: #10539B;*/
  margin: 100px auto;
  text-align: center;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}

.my > span {
  font-size: 36px;
  font-weight: bold;
}

.top_b1 {
  float: left;
  width: 160px;
  height: 5px;
  background-color: #10539B;
  opacity: 0.8;
  margin-top: 40px;
  margin-left: -151px;
}

.enterprise {
  display: flex;
  justify-content: center;
  width: 100%;
  /*height: 623px;*/
  /*background-color: #78b375;*/
  margin-top: 61px;
}

.dv1 {
  width: 676px;
  height: 528px;
  /*background-color: #10539B;*/
}

.text_wb {
  width: 480px;
  /*height: 226px;*/
  /*background-color: #f10215;*/
  text-align: left;
  margin-left: 50px;
  /*margin-top: 205px;*/
  line-height: 30px;
  color: #333333;
}

/*底部*/
.bottom_d {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 360px;
  background-color: #191919;
  text-align: left;
}

/*底部*/
.bottom_d {
  display: flex;
  justify-content: space-around;
  width: 100%;
  height: 360px;
  background-color: #191919;
  text-align: left;
}

.logo_d {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 250px;
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.logo_d > img {
  width: 300px;
  height: 90px;
  margin-left: -28px;
  margin-top: 38px;
}

.wx_logo {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  width: 320px;
  height: 190px;
  /*background-color: #554ace;*/
  margin-top: 90px;
  color: white;
  line-height: 30px;
}

.wx_logo > img {
  width: 142px;
  height: 142px;
}

.tabs_l>img{
  width: 100%;
}

.el-carousel__item h3 {
  color: #475669;
  font-size: 18px;
  opacity: 0.75;
  line-height: 300px;
  margin: 0;
}

.el-carousel__item:nth-child(2n) {
  background-color: #99a9bf;
}

.el-carousel__item:nth-child(2n+1) {
  background-color: #d3dce6;
}

.mouse-hover:hover {
  cursor: pointer;
}
</style>
